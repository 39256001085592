import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import {addLocaleData, IntlProvider} from 'react-intl';
import en from 'react-intl/locale-data/en';
import it from 'react-intl/locale-data/it';
import messaggesEn from './messages_en'
import messaggesIt from './messages_it'

addLocaleData([...en, ...it]);



let i18nConfigs = {
    it: {
        locale: 'it',
        messages: messaggesIt
    },
    en: {
        locale: 'en',
        messages: messaggesEn
    }
};
let i18nConfig  =i18nConfigs['en'];
if (navigator.language.startsWith('it')){
    i18nConfig = i18nConfigs['it'];
}
// console.log(navigator.language);

ReactDOM.render(
    (<IntlProvider
        locale={i18nConfig.locale} key={i18nConfig.locale} messages={i18nConfig.messages}>
        <App />
    </IntlProvider>), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
