import React, { Component } from 'react';
import './fonts.css';
import './App.css';
import { HashRouter as Router, Route, Link } from "react-router-dom";
import {FormattedHTMLMessage, FormattedMessage} from "react-intl";
import ScrollToTop from 'react-router-scroll-top'

const App = () => (
    // https://reacttraining.com/react-router/web/example/basic

    <Router>
      <ScrollToTop>
        <div className="App">
          <header className="App-header">
            <Link className="App-link-no-decoration" to="/">
                <div className="header">
                  Stefano<br/>
                </div>
            </Link>
          </header>
          <div className="App-body">
              <Route exact path="/" component={Home} />
              <Route path="/cv" component={CV} />
              <Route path="/contact" component={Contact} />
          </div>
        </div>
      </ScrollToTop>

    </Router>
);

class HomeLink extends Component {
    render() {
        return (
            <Link className="home-link" to={this.props.to}><FormattedMessage id={this.props.value}/></Link>
        );
    }
}

const Home = () => (
    <div>
      <FormattedHTMLMessage id="home.text"/>
      <HomeLink to="cv" value="link.cv"/>
      <HomeLink to="contact" value="link.contact"/>
     
    </div>
);
 //<img src={villa} className="photo" alt="" /> per mettere una foto

class PageTitle extends Component {
    render() {
        return (
            <h2 className="App-link">
              <Link className="titleLink" to={this.props.previous}>&lt;&lt;</Link>
              &nbsp;&nbsp;&nbsp;
              <FormattedMessage id={this.props.value}/>&nbsp;&nbsp;&nbsp;
              <Link className="titleLink" to={this.props.next}>&gt;&gt;</Link>
            </h2>
        );
    }
}


const CV = () => (
  <div>
      <PageTitle value="link.cv" previous="/" next="Contact"/>
  </div>
);

const Contact = () => (
    <div>
        <PageTitle value="link.contact" previous="CV" next="/"/>
    </div>
);

export default App;
